import BaseService from './BaseService'
import ApiService from "./ApiService";

export const getCustomerOnWaiting = async () => {
    const url = '/customers?itemsPerPage=100000&status=P&order[createdAt]=desc'
    return await BaseService.get(url)
}

export const getCustomerOnDocumentWaiting = async () => {
    const url =
        '/customers?itemsPerPage=100000&kycStatus[]=N&kycStatus[]=P&order[createdAt]=desc'
    return await BaseService.get(url)
}



export const createCustomer = async (data) => {
    const url = '/customers'
    return await BaseService.post(url, data)
}


export const createCorporateCustomer = async (data) => {
    const url = '/customers/corporates'
    return await BaseService.post(url, data)
}



export const createGroupCustomer = async (data) => {
    const url = '/customers/groups'
    return await BaseService.post(url, data)
}

export const updateCustomer = async (data, id) => {
    const url = '/customers/' + id
    return await BaseService.put(url, data)
}


export const updateCorporateCustomer = async (data, id) => {
    const url = '/customers/corporates/' + id
    return await BaseService.put(url, data)
}

export const getSearchCustomers = (url_, pageSize = 10, page = 1) => {
    const url = `/customers?pagination=true&page=${page}&itemsPerPage=${pageSize}${url_ !== '' ? '&' : ''
        }${url_}&order[createdAt]=desc`

    return BaseService.get(url, {
        headers: {
            "Content-Type": "application/ld+json",
            'Accept': 'application/ld+json',
        }
    })
}

export const getCustomers = () => {
    const url = '/customers?itemsPerPage=500000'

    return BaseService.get(url)
}


export const getCustomersByFullname = (fullname) => {
    const url = '/customers?itemsPerPage=500000&fullName=' + fullname

    return BaseService.get(url)
}

export const createKycDocument = async (data) => {
    const url = '/kyc_documents'

    return await BaseService.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

export const getCustomerById = (id) => {
    const url = `/customers/` + id
    return BaseService.get(url)
}

export const getUserById = (id) => {
    const url = `/users/` + id
    return BaseService.get(url)
}

export const getCustomerById_ = (name) => {
    const url = `/customers?firstName=${name}`
    return BaseService.get(url)
}

export const customerValidation = (id) => {
    const url = `/customers/${id}/validation`
    return BaseService.put(url, {})
}

export const customerDelete = (id) => {
    const url = `/customers/${id}`
    return BaseService.delete(url)
}

export const getCustomerKycDocuments = (id) => {
    const url = `/kyc_documents?customer=${id}&order[uploadedAt]=desc`

    return BaseService.get(url)
}


export const getHolderKycDocuments = (holderId) => {
    const url = `/kyc_documents?holder=${holderId}&order[uploadedAt]=desc`

    return BaseService.get(url)
}

export const validateKycDocument = async (id, typeValidation = true) => {
    const url = '/kyc_documents/' + id + '/verify'
    return await BaseService.put(url, {
        verified: typeValidation,
    })
}


export const updateKycDocument = async (id, data) => {
    const url = '/kyc_documents/' + id
    return await BaseService.put(url, data)
}

export const removeKycDocument = async (id) => {
    const url = '/kyc_documents/' + id

    return await BaseService.delete(url)
}

export const getCustomerAccesses = (id) => {
    const url = `/accesses?customer=${id}`
    return BaseService.get(url)
}



export const getHoldersByCustomer = (id) => {
    const url = `/holders?customer=${id}`
    return BaseService.get(url)
}



export const getHolderById = (id) => {
    const url = `/holders/${id}`
    return BaseService.get(url)
}


export const createAccess = async (data) => {
    const url = '/accesses'
    return await BaseService.post(url, data)
}

export const verifyAccess = async (data) => {
    const url = '/accesses/auth'
    return await BaseService.post(url, data)
}

export const getSearchBlacklistRequests = (url_, pageSize = 10, page = 1) => {
    const url = `/blacklist_requests?pagination=true&page=${page}&itemsPerPage=${pageSize}${url_ !== '' ? '&' : ''
        }${url_}&order[initiatedAt]=desc`

    return BaseService.get(url, {
        headers: {
            "Content-Type": "application/ld+json",
            'Accept': 'application/ld+json',
        }
    })
}

export const getBlacklistRequests = (customerId) => {
    const url = `/blacklist_requests?customer=${customerId}&order[initiatedAt]=desc`
    return BaseService.get(url)
}

export const createBlacklistRequest = async (data) => {
    const url = '/blacklist_requests'
    return await BaseService.post(url, data)
}

export const deleteBlacklistRequest = async (id) => {
    const url = '/blacklist_requests/' + id
    return await BaseService.delete(url)
}

export const validateBlacklistRequest = async (data) => {
    const url = '/blacklist_requests/validations'
    return await BaseService.post(url, data)
}

export const rejectBlacklistRequest = async (data) => {
    const url = '/blacklist_requests/rejections'
    return await BaseService.post(url, data)
}

