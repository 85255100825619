import { createSlice } from '@reduxjs/toolkit'
import { infosCallBegan } from '../actions/infos'

const slice = createSlice({
    name: 'infos',
    initialState: {
        list: [],
        isOpening: false,
        loading: true,
    },
    reducers: {
        infoRequested: (state, action) => {
            state.loading = true
        },
        infoReceived: (state, action) => {
            state.list.push(action.payload)
            state.isOpening = true
        },
        infoRequestedFailed: (state) => {
            state.loading = false
        },
        remove: (state, action) => {
            state.list = state.list.filter(
                (item) => item.code !== action.payload
            )
        },
        open: (state, action) => {
            state.isOpening = action.payload
        },
    },
})

export default slice.reducer

const { infoRequested, infoReceived, infoRequestedFailed, remove, open } =
    slice.actions

export const updateInfos =
    ({ type, code }) =>
    (dispatch) => {
        console.log("Update begin", code)
        return dispatch(
            infosCallBegan({
                type: type,
                code: code,
                onStart: infoRequested.type,
                onSuccess: infoReceived.type,
                onFailed: infoRequestedFailed.type,
            })
        )
    }

export const openDrawer = (payload) => (dispatch) => {
    return dispatch({ type: open.type, payload: payload })
}

export const removeInfo = (code) => (dispatch) => {
    return dispatch({ type: remove.type, payload: code })
}
