import appConfig from 'configs/app.config'

export const removeNullValues = (configs) => {
    configs = configs.filter((value) => value != null)
    configs = configs.map((value) => {
        let subMenu = value?.subMenu?.filter((value) => value != null)
        return {...value, subMenu}
    })

    return configs
}

export const parseNavigationConfigs = (navigationConfigs) => {
    const configModules = process.env.REACT_APP_MODULES === null || process.env.REACT_APP_MODULES === undefined || process.env.REACT_APP_MODULES === '' ? appConfig.modules : process.env.REACT_APP_MODULES.split(', ')

    return navigationConfigs.filter((value) => configModules.includes(value.key?.toLowerCase()))
}

export const isModuleAuthorized = (module) => {
    const configModules = process.env.REACT_APP_MODULES === null || process.env.REACT_APP_MODULES === undefined || process.env.REACT_APP_MODULES === '' ? appConfig.modules : process.env.REACT_APP_MODULES.split(', ')

    return configModules.includes(module.toLowerCase())
}

export const isFeatureAuthorized = (module, feature) => {
    const configModules = process.env.REACT_APP_MODULES === null || process.env.REACT_APP_MODULES === undefined || process.env.REACT_APP_MODULES === '' ? appConfig.modules : process.env.REACT_APP_MODULES.split(', ')

    if (!configModules.includes(module)) return false

    const envModuleKeyForFeature = `REACT_APP_FEATURES_${module?.toUpperCase()}`
    const configModuleKeyForFeature = `feats_${module}`


    const features = process.env[envModuleKeyForFeature] === null || process.env[envModuleKeyForFeature] === undefined || process.env[envModuleKeyForFeature] === '' ? appConfig[configModuleKeyForFeature] : process.env[envModuleKeyForFeature].split(', ')

    return features.includes(feature?.toLowerCase())
}

export const getEnvVars = (key) => {
    const envModuleKeyForFeature = `REACT_APP_${key?.toUpperCase()}`

    return process.env[envModuleKeyForFeature.toUpperCase()] === null || process.env[envModuleKeyForFeature.toUpperCase()] === undefined || process.env[envModuleKeyForFeature.toUpperCase()] === '' ? appConfig[key] : process.env[envModuleKeyForFeature.toUpperCase()];
}
